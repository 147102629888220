export const loadCategories = {
  loadCategoriesRequest: state => {
    state.loading.categories = true;
  },
  loadCategoriesSuccess: (state, {payload}) => {
    state.loading.categories = false;
    state.categories = payload.filter(({ id }) => id !== 35);
    state.selectedCategoryIdZones = payload[0].id
  },
  loadCategoriesFailure: state => {
    state.loading.categories = false;
  },
}
